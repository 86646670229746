const actions = {
	CHANGE_INVITE_MODAL_STATUS: "CHANGE_INVITE_MODAL_STATUS",
	FETCH_USERS: "FETCH_USERS",
	INVITE_USER: "INVITE_USER",
	INVITE_USER_SUCCESS: "INVITE_USER_SUCCESS",
	INVITE_USER_FAILURE: "INVITE_USER_FAILURE",
	FETCH_USERS_SUCCESS: "FETCH_USERS_SUCCESS",
	FETCH_USERS_FAILURE: "FETCH_USERS_FAILURE",
	SET_USERS_PAGE_SIZE: "SET_USERS_PAGE_SIZE",
	SET_USERS_PAGINATION: "SET_USERS_PAGINATION",
	UPDATE_SELECTED_USERS: "UPDATE_SELECTED_USERS",
	UPDATE_USERS_SELECT_ALL: "UPDATE_USERS_SELECT_ALL",
	USER_DELETE_CONFIRM_MODAL_VISIBLE: "USER_DELETE_CONFIRM_MODAL_VISIBLE",
	RESEND_CONFIRM_MODAL_VISIBLE: "RESEND_CONFIRM_MODAL_VISIBLE",
	DELETE_USERS: "DELETE_USERS",
	RESEND_INVITE: "RESEND_INVITE",
	DELETE_USERS_SUCCESS: "DELETE_USERS_SUCCESS",
	DELETE_USERS_FAILURE: "DELETE_USERS_FAILURE",
	RESEND_INVITE_SUCCESS: "RESEND_INVITE_SUCCESS",
	RESEND_INVITE_FAILURE: "RESEND_INVITE_FAILURE",
	SET_SETTINGS_ACTIVE_LIST: "SET_SETTINGS_ACTIVE_LIST",
	FETCH_COMPANY_ROLES: "FETCH_COMPANY_ROLES",
	FETCH_COMPANY_ROLES_SUCCESS: "FETCH_COMPANY_ROLES_SUCCESS",
	FETCH_COMPANY_ROLES_FAILURE: "FETCH_COMPANY_ROLES_FAILURE",
	ASSIGN_TASK_DRAWER_VISIBLE: "ASSIGN_TASK_DRAWER_VISIBLE",
	GET_MERGE_TOKEN: "GET_MERGE_TOKEN",
	MERGE_TOKEN: "MERGE_TOKEN",
	CREATE_MERGE_TOKEN: "CREATE_MERGE_TOKEN",
	UPDATE_MERGE_INTEGRATION: "UPDATE_MERGE_INTEGRATION",
	CREATE_TRELLO_TICKET: "CREATE_TRELLO_TICKET",
	FETCH_MERGES: "FETCH_MERGES",
	USER_MERGES: "USER_MERGES",
	MERGE_INT_SELECTED_LIST: "MERGE_INT_SELECTED_LIST",
	DELETE_INT_MERGES: "DELETE_INT_MERGES",
	UNLINK_INT_MERGES: "UNLINK_INT_MERGES",
	FETCH_SLACK: "FETCH_SLACK",
	SET_SLACK_INTEGRATION: "SET_SLACK_INTEGRATION",
	CREATE_SLACK_INT: "CREATE_SLACK_INT",
	UPDATE_SLACK_INT: "UPDATE_SLACK_INT",
	CREATE_INTEGRATION_TOKEN: "CREATE_INTEGRATION_TOKEN",
	UPDATE_INTEGRATION_TOKEN: "UPDATE_INTEGRATION_TOKEN",
	FETCH_USER_INTEGRATION: "FETCH_USER_INTEGRATION",
	FETCH_INTEGRATIONS: "FETCH_INTEGRATIONS",
	SET_EMAIL_INTEGRATIONS: "SET_EMAIL_INTEGRATIONS",
	DESTROY_EMAIL_INTEGRATION: "DESTROY_EMAIL_INTEGRATION",
	DESTROY_SLACK: "DESTROY_SLACK",
	FETCH_ATS_CANDIDATES: "FETCH_ATS_CANDIDATES",
	SET_ATS_CANDIDATES: "SET_ATS_CANDIDATES",
	UPDATE_SELECTED_ATS_CANDIDATES: "UPDATE_SELECTED_ATS_CANDIDATES",
	IMPORT_SELECTED_CANDIDATES: "IMPORT_SELECTED_CANDIDATES",
	FETCH_CUSTOM_FIELDS: "FETCH_CUSTOM_FIELDS",
	SET_CUSTOM_FIELDS: "SET_CUSTOM_FIELDS",
	FETCH_INTERVIEW_STAGES: "FETCH_INTERVIEW_STAGES",
	SET_INTERVIEW_STAGES: "SET_INTERVIEW_STAGES",
	SET_HIRED_STAGE: "SET_HIRED_STAGE",
	FETCH_CURRENT_STAGE: "FETCH_CURRENT_STAGE",
	SET_CURRENT_HIRED_STAGE: "SET_CURRENT_HIRED_STAGE",
	SET_TWILIO_CONFIG: "SET_TWILIO_CONFIG",
	SET_TWILIO_LOADING: "SET_TWILIO_LOADING",
	SET_FETCHING_LINK_TOKEN: "SET_FETCHING_LINK_TOKEN",
};
export default actions;
